.partners {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 0;
  font-family: var(--primary-font);
  gap: 2.5rem;
}

.section_title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--primary-color);
  font-family: var(--primary-font);
  line-height: 110%;
}

.partner_card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
}

.partner_card {
  width: 220px;
  min-height: 260px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 1.25rem 0.7rem;
  border-radius: 10px;
  position: relative;
  text-align: center;
}

.partner__name {
  font-family: var(--primary-font);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #27265b;
}

.partner__role {
  font-family: var(--primary-font);
  color: rgba(5, 54, 101, 0.9);
  font-size: 1.25rem;
  font-weight: 600;
}

.partner__organization {
  font-weight: 300;
  font-size: 0.8rem;
  color: rgba(5, 54, 82, 0.8);
}

@media only screen and (max-width: 576px) {
  .section_title {
    font-size: 1.75rem;
  }
  .partner_card_container {
    width: 100%;
  }
}
