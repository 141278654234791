.container {
  margin-inline: auto;
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
@media only screen and (max-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}
@media only screen and (max-width: 992px) {
  .container {
    max-width: 720px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}
@media only screen and (max-width: 576px) {
  .container {
    max-width: 100%;
  }
}
