.aboutCard {

  width: 220px;
  min-height: 300px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
}
.aboutCard:hover {
  background: #27265b;
  color: #fff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.aboutcard_icon {
  font-size: 3.5rem;
  color: #27265b;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutcard_title {
  font-size: 1.25rem;
  text-align: center;
}
.aboutcard_desc {
  font-size: 0.9rem;
  font-weight: 400;
  opacity: 0.8;
  text-align: center;
}
.aboutCard:hover .aboutcard_icon {
  color: #fff;
}
