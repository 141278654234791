.highlightsCard {
    width: 220px;
    min-height: 310px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 10px;
    transition: transform 0.3s;
    box-shadow: 0px 2px 9px rgb(0 0 0 / 15%);
}

.highlightsCard:hover {
    background-color: #03012c12;
}

.highlightsCard-icon {
    box-sizing: border-box;
    padding: 1rem;
}

.card-icon {
    font-size: 1.85rem;
    font-weight: 400;
}

.highlightsCard-title {
    font-family: var(--primary-font);
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    padding-bottom: 0.7rem;
    text-align: center;
    line-height: 110%;
}

.highlightsCard-desc {
    text-align: center;
    font-family: var(--primary-font);
    font-size: 0.95rem;
    font-weight: 400;
    color: var(--secondary-color);
}

