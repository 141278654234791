.organizations {
  min-height: 75vh;
  padding-top: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--primary-font);
}

.org_title {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: 600;
  padding: 0 0 2.5rem 0;
}

.org-descriptions {
  color: var(--primary-color);
  margin-top: 2rem;
}
.org_event1,
.org_event2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
}
.orgevent_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.org_event2 img {
  background-color: #021323ff;
  border-radius: 50%;
}

.orgevent1_desc,
.orgevent2_desc {
  flex: 0.5;
}

.orgevent_img > img {
  width: 100%;
}

.org_event2__img {
  width: 90% !important;
}
@media only screen and (max-width: 768px) {
  .org_event2__img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 576px) {
  .org_event1 {
    flex-direction: column;
  }
  .org_event2 {
    flex-direction: column-reverse;
  }
  .org_event2 {
    gap: 1rem;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 576px) {
  .orgevent1_desc,
  .orgevent2_desc {
    flex: 1;
    width: 80%;
  }
  .orgevent_img {
    flex: 1;
  }
}

.orgdesc_name {
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.org_event1 .orgevent_img {
  flex: 0.5;
  width: 250px;
}

.org_event2 .orgevent_img {
  flex: 0.5;
  width: 250px;
  scale: 0.7;
}
