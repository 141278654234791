.navbar {
  height: 80px;
  position: fixed;
  padding: 0 2rem;
  z-index: 999;
  top: 0;
  width: 100%;
}
.navbar__main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_logo img {
  height: 100%;
  width: 175px
}

.nav_title {
  font-family: var(--primary-font);
  font-size: 1.75rem;
  font-weight: 700;
  color: white;
}

.nav_contents {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.nav__link {
  gap: 2.5rem;
  font-family: var(--primary-font);
  font-style: normal;
  align-items: center;
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  opacity: 0.8;
  font-weight: 500;
}

.nav__link:hover {
  opacity: 1;
}

a {
  text-decoration: none;
}

.nav_hamburger {
  display: none;
}

.avatarIcon {
  cursor: pointer;
}


@media screen and (max-width: 1200px) {
  .nav_contents {
    gap: 1rem;
  }
  .nav__link {
    font-size: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .nav_hamburger {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: white;
  }

  .nav_contents {
    display: none;
  }

  .nam_menu_icon {
    font-size: 1.75rem;
    color: var(--tertiary-color);
  }

  .navbar__mob {
    background-color: #04005e;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0rem 7rem;
    position: relative;
  }

  .navbar_mob_close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    font-size: 2rem;
    color: white;
  }

  .navbar__mobcontents {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .navmob__link {
    gap: 2.5rem;
    font-family: var(--primary-font);
    font-style: normal;
    align-items: center;
    color: #ffffff;
    font-size: 1.3rem;
    cursor: pointer;
    opacity: 0.6;
    text-align: center;
  }

  .navmob__link:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    padding: 0 1rem;
  }
  .navbar__mob {
    padding: 0 5rem;
    position: relative;
  }
  .navbar_mob_close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    font-size: 2rem;
    color: white;
  }
  
}
