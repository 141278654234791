.about {
  padding: 3.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  font-family: var(--primary-font);
}

.about-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: 600;
  padding-bottom: 2rem;
  text-align: center;
}

.about-desc {
  margin-top: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: 1rem;
}

@media only screen and (max-width: 992px) {
  .about-desc {
    flex-direction: column;
    text-align: center;
  }
}

.about-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}

@media only screen and (max-width: 768) {
  .about-cards {
    flex-direction: column;
  }
}
