@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700&display=swap');

* {
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-padding: 80px;
    scroll-behavior: smooth;
}
  
/* VARIABLES */
:root {
    --primary-font: 'Poppins', sans-serif;
    --primary-color: #03012c;
    --secondary-color:  #777;
    /* --primary-font: 'Raleway', sans-serif;
    --secondary-font: 'Work Sans', sans-serif;
    --bg-color: #E6F4F4;
    --primary-color: #0B8B8B;
    --secondary-color: #86CECE;
    --text-main: #333333; */
}
  