.highlights {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem 2rem;
    gap: 2rem;
}

.highlights-heading {
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    color: var(--primary-color);
}

.highlights-title {
    font-size: 2.5rem;
    font-family: var(--primary-font);
    color: var(--primary-color);
    font-weight: 600;

}

.highlights-desc {
    font-family: var(--primary-font);
    font-size: 0.95rem;
    font-weight: 400;
    color: var(--primary-color);
    flex-wrap: wrap;
}

.highlights-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

@media only screen and (max-width: 992px) {
  .highlights-card {
    max-width: 720px;
  }
}
