:root {
    --member-animation: all 0.35s ease-in-out;
  }

.timelineSection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    padding-top: 2rem;
    background-color: var(--primary-color);
}

.white {
    color: #ffffff !important;
}

#timeline-container {
    overflow: auto;
    padding: 60px 0;
    width: 100%;
  }
  #timeline-container .inner-container {
    max-width: 1000px;
    margin: 0 auto;
  }

  .timeline {
    margin: 0 auto;
    position: relative;
    left: 120px;
    width: 80%;
    max-width: 900px;
    margin-top: 16px;
    margin-left: 5%;
  }
  
  .timeline-item {
    color: #ffffff;
    font-family: var(--primary-font);
    text-align: start;
    font-size: 1.2rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-left: 4px solid #fff;
    border-bottom: 1px solid #ffffff;
    position: relative;
    list-style-type: none;
    --item-width: calc(100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .t_bold {
    font-weight: 600;
  }

  .timeline-item::after {
    content: attr(data-date);
    position: absolute;
    right: calc(var(--item-width) + 40px);
    top: 16px;
    float: right;
    font-weight: bold;
    white-space: nowrap;
    font-weight: 600;
    font-size: 1.1rem;
    opacity: 1;
  }
  .timeline-item::before {
    content: "";
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    position: absolute;
    left: -10px;
    top: 21px;
  }
  .timeline-item:last-child {
    border-bottom: none;
  }

  .timeLine_date {
    color: #ffffff;
    font-size: 1.4rem;
    margin: 1rem 0;
    font-family: var(--primary-font);
  }
  
  @media only screen and (max-width: 800px) {
    .timeline {
      margin: 0 auto;
      position: relative;
      left: 15px;
    }
    .timeline .timeline-item {
      font-size: 1rem;
    }
    .timeline .timeline-item::after {
      right: calc(var(--item-width) + 30px);
    }
    .timeline-item::after {
        font-size: 0.9rem;
    }
    .timeLine_date {
        font-size: 1.2rem;
        margin: 1.25rem 0;
      }
      
  }
  @media only screen and (max-width: 800px) {
    .timeline-item {
      padding: 1rem 1.5rem 1rem 1rem;
    }
    .timeline-item::after {
      right: calc(var(--item-width) + 20px);
    }
  }