.colleges {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    overflow: hidden;
}

.marquee_slide {
    display: flex;
    margin-top: 2rem;
}

.clubsnorgs_marquee {
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.colleges_details {
    display: flex;
    width: 250px;
    border-radius: 10px;
    pointer-events: none;
    cursor: pointer;
    transition: ease-in 0.2s;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.colleges_details img {
    width: 200px;
    cursor: pointer;

}

/* .colleges_img img:hover {
    transform: scale(1.1);
} */

.colleges_details h4 {
    font-family: var(--primary-font);
    color: rgba(0, 0, 0, 0.944);
    font-size: 1.25rem;
    font-weight: 600;
}


@media only screen and (max-width: 576px) {
    .clubsnorgs_marquee {
        gap: 2rem;
    }

    .colleges_details img {
        width: 180px;
    }

}