.team {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 0;
  font-family: var(--primary-font);
  color: var(--primary-color);
  gap: 2.5rem;
}

.team_title {
  font-size: 2.5rem;
  font-weight: 600;
}

.team_card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.contact_card {
  width: 220px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  background-color: #ECECEC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.25rem 0.7rem;
  border-radius: 10px;
}

.contact__image > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.contact__name {
  font-family: var(--primary-font);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}

.contact__role {
  font-family: var(--primary-font);
  color: rgba(5, 54, 101, 0.71);
  font-size: 0.8rem;
  font-weight: 500;
}

.contact_icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  flex-shrink: 0;
}
.contact_icons a {
  color: inherit;
}

.contactIcon {
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: #03012c;
}

.contactIcon:hover {
  transform: scale(1.25);
}
